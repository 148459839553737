// Customizable Area Start
import React from "react";
import {
    Box,
    Grid,
    Typography, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Tabs, Tab, Breadcrumbs, Link
} from "@material-ui/core";
import { withStyles, createTheme } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { styled } from '@material-ui/styles';

import Header from "../../landingpage/src/Header.web";

import VerifyInvoiceController, { Props, configJSON, DateTextFieldProps, SubTotalInvoiceBoxProps, SubTotalChargesInvoiceProps, RentalItemColumn, RentalDescription } from "./verifyInvoiceController.web";

import { a11yProps } from "./CreateReport.web";

import Pagination from '@material-ui/lab/Pagination';
import moment from "moment";


export const theme = createTheme({});

const StyledButton = styled(Button)({
    background: '#ef7927',
    width: '20%',
    height: "45px",
    textTransform: 'capitalize',
    padding: '16px',
    fontWeight: 600,
    marginTop: '20px',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
        background: '#ef7927',
    }

});

const RentalTableCell = styled(TableCell)({
    fontWeight: 700
})

const RentalPaginationBox = styled(Box)({
    marginTop: "45px"
})

export const SubTotalInvoiceBox = (props: SubTotalInvoiceBoxProps) => {
    const { invoiceMetaData, classes,updatedSubtotalValue } = props

    return (
        <>
            {!updatedSubtotalValue ?
                <TextField variant="outlined" className={classes.textField}
                    value={`$ ${invoiceMetaData?.invoice_subtotal}`} InputProps={{ readOnly: true }} disabled /> :
                <TextField variant="outlined" className={classes.textField}
                    value={`$ ${Number(updatedSubtotalValue).toFixed(2)}`} InputProps={{ readOnly: true }} disabled />
            }
        </>
    )
}

export const SubTotalChargesInvoiceBox = (props: SubTotalChargesInvoiceProps) => {
    const { invoiceMetaData, classes } = props

    return (
        <>
            <Typography className={classes.titleStyle}>{configJSON.sAndHChargesText}</Typography>
            <TextField variant="outlined" className={classes.textField}
                value={`$ ${invoiceMetaData?.invoice_s_and_h_charges}`} InputProps={{ readOnly: true }} disabled />
        </>
    )
}

export const DateTextField = (props: DateTextFieldProps) => {
    const { invoiceMetaData, classes } = props
    return (
        <>
            <TextField variant="outlined" className={classes.textField}
                value={invoiceMetaData?.invoice_shipped_date ? moment(invoiceMetaData?.invoice_shipped_date).format("MM-DD-YYYY") : "Unknown"} InputProps={{ readOnly: true, endAdornment: (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" id="calendar"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="gray" stroke-width="2" transform="translate(-919 -1443)"><g transform="translate(920 1444)"><rect width="18" height="18" y="2" rx="2"></rect><path d="M13 0v4M5 0v4M0 8h18"></path></g></g></g></svg>) }} disabled />
            <Typography variant="body2" className={classes.titleStyle}>{configJSON.invoiceExpiaryDateText}</Typography>
            <TextField variant="outlined" className={classes.textField}
                value={invoiceMetaData?.date ? moment(invoiceMetaData?.date).format("MM-DD-YYYY") : "Unknown"} InputProps={{ readOnly: true, endAdornment: (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" id="calendar"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="gray" stroke-width="2" transform="translate(-919 -1443)"><g transform="translate(920 1444)"><rect width="18" height="18" y="2" rx="2"></rect><path d="M13 0v4M5 0v4M0 8h18"></path></g></g></g></svg>) }} disabled />

        </>
    )
}

export const CategoryTable = ({ categoryData, invoiceColumn, begins, ends,classes,taxSwitch,invoiceColumnWithTax }:any) => {
    const categoryName = Object.keys(categoryData)[0];
    const items = categoryData[categoryName];
  
    return (
      <Table key={categoryName}>
        {taxSwitch ?<TableHead>
          {invoiceColumnWithTax.map((column1:any) => (
            <TableCell
              key={column1.id}
              align={column1.align}
              style={{ minWidth: column1.minWidth, fontWeight: 700 }}
            >
              {column1.label}
            </TableCell>
          ))}
        </TableHead>:<TableHead>
          {invoiceColumn.map((column_inv:any) => (
            <TableCell
              key={column_inv.id}
              align={column_inv.align}
              style={{ minWidth: column_inv.minWidth, fontWeight: 700 }}
            >
              {column_inv.label}
            </TableCell>
          ))}
        </TableHead>}
  
        <TableBody className={classes.tableBodyBox}>
          {Array.isArray(items) && items.slice(begins, ends).map((item:any, itemIndex:number) => (
            <TableRow key={itemIndex}>
              <TableCell>{begins + itemIndex + 1}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>{item.ord_quantity}</TableCell>
              <TableCell>{item.order_unit}</TableCell>
              <TableCell>$ {item.price}</TableCell>
              {taxSwitch && <TableCell>{item.tax ? item.tax : 0} %</TableCell>}
              <TableCell>$ {item.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

export const PaginationAndSubtotal = ({ items, currentPage, categoryName, subtotalValue, invoiceRowsPerPage, handleDataPageChange,classes }:any) => (
    <>
    <Box className={classes.paginationBox}>
      <Pagination
        count={Math.ceil(items.length / invoiceRowsPerPage)}
        variant="outlined"
        shape="rounded"
        page={currentPage}
        data-testid="paginationTestId"
        onChange={(event, page) => handleDataPageChange(event, categoryName, page)}
      />
  
      <Box className={classes.subTotalBoxSegments}>
        <Typography className={classes.titleStyle}>{configJSON.subTotalText}</Typography>
        <TextField
          variant="outlined"
          className={classes.textField}
          value={`$ ${Number(subtotalValue)?.toFixed(2)}`}
          InputProps={{ readOnly: true }}
          disabled
        />
      </Box>
    </Box>
    </>
  );

export const TaxswitchTableHead = (props:any)=>{
    const {taxSwitch,invoiceColumnWithTax,invoiceColumn} = props
    return <>
    {taxSwitch ? <TableHead>
        {invoiceColumnWithTax.map((column:any) => (
            <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth, fontWeight: 700 }}
            >
                {column.label}
            </TableCell>
        ))}
    </TableHead>:<TableHead>
        {invoiceColumn.map((elm:any) => (
            <TableCell
                key={elm.id}
                align={elm.align}
                style={{ minWidth: elm.minWidth, fontWeight: 700 }}
            >
                {elm.label}
            </TableCell>
        ))}
    </TableHead>
    }
    </>
}

export const TaxswitchTableCell = (props:any)=>{
    const {taxSwitch,tax} = props;
    return <>
    {taxSwitch && <TableCell>{tax ?  tax : 0} %</TableCell>}
    </>
}

export const CategoryBox = (props:any)=>{
    const {categoryName,classes} = props
    return <>
    {categoryName !== "DefaultCategory" &&
                                                        <Box className={classes.categoryNameBox}>
                                                            <Typography variant="h5" className={classes.quotedetailsContent} style={{ fontWeight: 400 }}>{"Segment/Category"}</Typography>
                                                            <TextField
                                                                variant="outlined"
                                                                className={classes.textField}
                                                                value={`${categoryName}`}
                                                                InputProps={{ readOnly: true }}
                                                                style={{ width: 300, marginLeft: 20 }} disabled
                                                            />
                                                        </Box>}
            </>
}


export class VerifyInvoice extends VerifyInvoiceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes, } = this.props
        const { invoicePage, invoiceRowsPerPage, invoiceDetails } = this.state;

        
       
        const invoiceMetaData = this.props.updated_table_data.length > 0 && !invoiceDetails[this.state.invoiceTabIndex]?.message
    ? this.state.updated_table_data[this.state.invoiceTabIndex]?.editQuoteDetail 
    : invoiceDetails[this.state.invoiceTabIndex]?.invoice.data.attributes;

    const totalInvoiceValue = this.props.updated_table_data.length > 0 && !invoiceDetails[this.state.invoiceTabIndex]?.message
    ? this.state.updated_table_data[this.state.invoiceTabIndex]?.totalValue
    : invoiceDetails[this.state.invoiceTabIndex]?.invoice.data.attributes.total_price;


    const subtotalForOneSegment = this.props.updated_table_data[this.state.invoiceTabIndex]?.calculateValue ? this.props.updated_table_data[this.state.invoiceTabIndex]?.calculateValue : invoiceMetaData?.invoice_subtotal

    

const begin = (invoicePage - 1) * invoiceRowsPerPage;
const end = begin + invoiceRowsPerPage;
const totalData = Math.ceil(invoiceDetails[this.state.invoiceTabIndex]?.invoice_description?.length / invoiceRowsPerPage);

const invoiceData = invoiceDetails[this.state.invoiceTabIndex]?.invoice_description 
    || invoiceDetails[this.state.invoiceTabIndex]?.tablesData 
    || [];

const isFlatStructure = invoiceData.length > 0 && invoiceData.every((item: { [s: string]: unknown; } | ArrayLike<unknown>) => 
    !Object.values(item).some(value => Array.isArray(value))
);

const taxSwitch = this.state.updated_table_data[this.state.invoiceTabIndex]?.taxSwitch 
? this.state.updated_table_data[this.state.invoiceTabIndex]?.taxSwitch
: false;

        return (
            <>

                <Tabs
                    value={this.state.invoiceTabIndex}
                    onChange={this.handleTabIndexChange}
                    className={classes.invoiceInnerBox2}
                    data-testid="tabChangeid"
                >
                    {

                        this.state.invoiceTabs.slice(0, 8).map((data: any) => {
                            return (
                                <Tab
                                    label={
                                        <>
                                            <Box className={classes.invoicecurveBox}>
                                                <Box className={classes.invoicecurveInnerBox}></Box>
                                            </Box>
                                            <Typography variant="subtitle2" style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis", maxWidth: "8ch"
                                            }}>{data.name}</Typography>
                                            <Box className={classes.invoicecurveRightBox}>
                                                <Box className={classes.curveRightinvoiceInnerBox}></Box>
                                            </Box>
                                        </>
                                    }
                                    {...documentOcra11yProps(0)}
                                    title={data.name}
                                    className={classes.invoiceTitleBox}
                                />
                            )
                        })
                    }

                </Tabs>

                <Box className={classes.quoteBox}>
                    <Typography variant="h5" className={classes.viewquoteTitle}>{configJSON.viewInvoiceText}</Typography>

                    <Box className={classes.quoteDeatilBox}>
                        <Grid container spacing={2}>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" className={classes.quotedetailsContent}>{configJSON.invoiceDetailsText}</Typography>
                                    <Typography variant="body2" className={classes.titleStyle}>{configJSON.invoiceNumberText}</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={invoiceMetaData?.quote_id} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle} >{configJSON.invoiceDateText}</Typography>
                                    <DateTextField
                                        invoiceMetaData={invoiceMetaData}
                                        classes={classes}
                                    />
                                    <Typography variant="body2" className={classes.titleStyle}>{configJSON.invoiceTerms}</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={invoiceMetaData?.invoice_terms} InputProps={{ readOnly: true }} disabled />
                                </Box>
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" className={classes.quotedetailsContent}>{configJSON.supplierDetailsText}</Typography>
                                    <Typography variant="body2" className={classes.titleStyle}>{configJSON.supplierNameText}</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={invoiceMetaData?.project} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle}>{configJSON.supplierAddressText}</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={invoiceMetaData?.invoice_supplier_address} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle} >{configJSON.salesPersonText}</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={invoiceMetaData?.invoice_salesperson_name} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle} >{configJSON.supplierContactText}</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={invoiceMetaData?.invoice_supplier_contact} InputProps={{ readOnly: true }} disabled />
                                </Box>
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" className={classes.quotedetailsContent}>{configJSON.shipToText}</Typography>
                                    <Typography variant="body2" className={classes.titleStyle} >{configJSON.siteNameText}</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={invoiceMetaData?.invoice_site_name} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle} >{configJSON.siteAddressText}</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={invoiceMetaData?.invoice_site_address} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle} >{configJSON.contactPersonText}</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={invoiceMetaData?.invoice_site_person} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle} >{configJSON.siteContactText}</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={invoiceMetaData?.invoice_site_contact} InputProps={{ readOnly: true }} disabled />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <>
                        {invoiceDetails[this.state.invoiceTabIndex]?.invoice_rental_description?.length > 0 && <Box>
                            
                            <Typography variant="h5" className={classes.quotedetailsContent}>{configJSON.rentalDetailsText}</Typography>
                            <TableContainer>
                                
                                <Table>
                                    <TableHead>
                                        
                                        {this.state.invoiceRentalItemColumn.map((column: RentalItemColumn) => (
                                            <RentalTableCell
                                                key={column.id}
                                            >
                                                {column.label}
                                            </RentalTableCell>
                                        ))}
                                    </TableHead>
                                    <TableBody className={classes.tableBodyBox}>
                                        {
                                            invoiceDetails[this.state.invoiceTabIndex]?.invoice_rental_description?.slice(begin, end).map((invoiceData: RentalDescription, index: number) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{begin + index + 1} </TableCell>
                                                        <TableCell>{invoiceData.equipment}</TableCell>
                                                        <TableCell>{invoiceData.description}</TableCell>
                                                        <TableCell>{invoiceData.ord_quantity}</TableCell>
                                                        <TableCell>$ {invoiceData.minimum}</TableCell>
                                                        <TableCell>$ {invoiceData.day}</TableCell>
                                                        <TableCell>$ {invoiceData.week}</TableCell>
                                                        <TableCell>$ {invoiceData.month}</TableCell>
                                                        <TableCell>$ {invoiceData.amount}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <RentalPaginationBox>
                                <Pagination
                                    count={totalData}
                                    variant="outlined"
                                    shape="rounded"
                                    onChange={this.handleChangePage}
                                    page={invoicePage}
                                    data-testid="paginationTestId"
                                />
                            </RentalPaginationBox>
                            <Box className={classes.subTotalBox}>
                                <Typography className={classes.titleStyle}>{configJSON.subTotalText}</Typography>
                                <TextField variant="outlined" className={classes.textField}
                                    value={`$ ${invoiceMetaData?.invoice_rental_subtotal}`} InputProps={{ readOnly: true }} disabled />
                            </Box>
                        </Box>}

                        <Typography variant="h5" className={classes.quotedetailsContent}>{invoiceDetails[this.state.invoiceTabIndex]?.invoice_rental_description?.length > 0 ? configJSON.salesItemDetailsText : configJSON.itemDetailsText}</Typography>
                       
                        <Box>
                            {
                                isFlatStructure === true ?

                                    <TableContainer>
                                        <Table>
                                           
                                            <TaxswitchTableHead taxSwitch={taxSwitch} invoiceColumnWithTax={configJSON.commonTaxColumns} invoiceColumn={this.state.invoiceColumn}/>
                                            <TableBody className={classes.tableBodyBox}>
                                                {
                                                    
                                                    invoiceDetails[this.state.invoiceTabIndex]?.invoice_description.slice(begin, end).map((invoiceData: RentalDescription, index: number) =>{
                                                        return (
                                                        
                                                        <TableRow key={index}>
                                                            <TableCell>{begin + index + 1}</TableCell>
                                                            <TableCell>{invoiceData.description}</TableCell>
                                                            <TableCell>{invoiceData.ord_quantity}</TableCell>
                                                            <TableCell>{invoiceData.order_unit}</TableCell>
                                                            <TableCell>$ {invoiceData.price}</TableCell>
                                                            <TaxswitchTableCell taxSwitch={taxSwitch} tax={invoiceData.tax}/>
                                                            <TableCell>$ {invoiceData.amount}</TableCell>
                                                        </TableRow>
                                                    )})
                                                }
                                            </TableBody>

                                        </Table>
                                        <Box style={{ marginTop: "45px" }}>
                                            <Pagination
                                                count={totalData}
                                                variant="outlined"
                                                shape="rounded"
                                                onChange={this.handleChangePage}
                                                page={invoicePage}
                                                data-testid="paginationTestId"
                                            />
                                        </Box>
                                        <Box className={classes.subTotalBox}>
                                            <Typography className={classes.titleStyle}>{configJSON.subTotalText}</Typography>
                                            <TextField variant="outlined" className={classes.textField}
                                                value={`$ ${Number(subtotalForOneSegment).toFixed(2)}`} InputProps={{ readOnly: true }} disabled />
                                        </Box>
                                    </TableContainer> :
                                    <TableContainer>
                                        {this.state.invoiceDetails[this.state.invoiceTabIndex]?.invoice_description?.map((categoryData: { [x: string]: any; }, categoryIndex: number) => {
                                            const categoryName = Object.keys(categoryData)[0];
                                            const items = categoryData[categoryName];
                                            const currentPage = this.state.pagination[categoryName] || 1;
                                            const begins = (currentPage - 1) * invoiceRowsPerPage;
                                            const ends = begins + invoiceRowsPerPage;
                                            const subtotalValue = items.length > 0 ? items[0].subtotalValue : 0;
                                            return (
                                                <>
                                                    {

                                                        <CategoryBox categoryName={categoryName} classes={classes}/>
                                                    }



                                                    <CategoryTable
                                                    categoryData={categoryData}
                                                    invoiceColumn={this.state.invoiceColumn}
                                                    begins={begins}
                                                    ends={ends}
                                                    classes={classes}
                                                    taxSwitch={taxSwitch}
                                                    invoiceColumnWithTax={configJSON.commonTaxColumns}
                                                    />
                                                    
                                                    <PaginationAndSubtotal
                                                    items={items}
                                                    currentPage={currentPage}
                                                    categoryName={categoryName}
                                                    subtotalValue={subtotalValue}
                                                    invoiceRowsPerPage={invoiceRowsPerPage}
                                                    handleDataPageChange={this.handleDataPageChange}
                                                    classes={classes}
                                                    />
                                                </>
                                            )
                                        })}

                                    </TableContainer>
                            }
                        </Box>
                    </>

                    <Box className={classes.invoiceTotalAmountBox}>
                        <Box>
                            <Box className={classes.invoiceSubtotalContentBox}>
                                <Typography className={classes.titleStyle}>{configJSON.subTotalOrGrossAmountText}</Typography>
                                <SubTotalInvoiceBox
                                    invoiceMetaData={invoiceMetaData}
                                    classes={classes}
                                    updatedSubtotalValue={this.state.updated_table_data?.[this.state.invoiceTabIndex]?.calculateValue}
                                />

                            </Box>
                            <Box className={classes.invoiceSubtotalContentBox}>


                            </Box>
                            <Box className={classes.invoiceSubtotalContentBox}>
                                <Typography className={classes.titleStyle}>{configJSON.sAndHChargesText}</Typography>
                                <TextField variant="outlined" className={classes.textField}
                                    value={`$ ${invoiceMetaData?.invoice_s_and_h_charges}`} InputProps={{ readOnly: true }} disabled />
                            </Box>
                            <Box className={classes.invoiceSubtotalContentBox}>
                                <Typography className={classes.titleStyle}>{configJSON.otherChargesText}</Typography>
                                <TextField variant="outlined" className={classes.textField}
                                    value={`$ ${invoiceMetaData?.invoice_other_charges}`} InputProps={{ readOnly: true }} disabled />
                            </Box>
                            <Box className={classes.invoiceSubtotalContentBox}>
                                <Typography className={classes.titleStyle}>{configJSON.discountText}</Typography>
                                <TextField variant="outlined" className={classes.textField}
                                    value={`$ ${invoiceMetaData?.discount}`} InputProps={{ readOnly: true }} disabled />
                            </Box>
                            <Box className={classes.invoiceTaxContent}>
                                <Box>
                                    <Typography className={classes.titleStyle}>{configJSON.taxText}</Typography>
                                </Box>
                                <Box className={classes.invoiceTaxInputBox}>
                                    <TextField variant="outlined" className={classes.textFieldTaxPercentage}
                                        value={`${invoiceMetaData?.invoice_tax_percent}%`} InputProps={{ readOnly: true }} disabled />
                                    <TextField variant="outlined" className={classes.textField}
                                        value={`$ ${invoiceMetaData?.invoice_tax}`} InputProps={{ readOnly: true }} disabled />
                                </Box>
                            </Box>
                            <Box className={classes.invoiceSubtotalContentBox}>
                                <Typography style={{ fontWeight: 600 }} className={classes.totalStyle}>{configJSON.totalText}</Typography>
                                <TextField variant="outlined" className={classes.textField}
                                    value={`$ ${totalInvoiceValue}`} InputProps={{ readOnly: true }} disabled />
                            </Box>
                        </Box>
                    </Box>
                </Box >
                <Box className={classes.buttonBox}>
                    <Button variant="outlined" data-testid="goBackTestid" style={{ textTransform: "none" }} onClick={() => { this.props.goBackButtonNavigation("", 2) }} className={classes.goBackButton}>{configJSON.goBackButtonText}</Button>
                    <Button variant="outlined" data-testid="continue_test_id" style={{ textTransform: "none" }} className={classes.continueButton} onClick={() => { this.props.onContinueButtonClick("", 4) }}>{configJSON.continuebuttonText}</Button>

                </Box>


            </>
        );
    }
}

export const VerifyInvoiceStyle = () => ({




})

export const documentOcra11yProps = (indextab: any) => {
    return {
        id: `simple-tab-${indextab}`,
        'aria-controls': `simple-tabpanel-${indextab}`,
    };
}

export default withStyles(VerifyInvoiceStyle)(VerifyInvoice)

// Customizable Area End
